import _ from 'lodash';
import axios from 'axios';

const hightlightQueryMatching = (title, queryString) => {
    const queryRegExp = new RegExp(queryString, 'i');
    return title.replace(queryRegExp, `<strong>${queryString}</strong>`);
};

const createSuggestionNode = (
    { name, short_description, link, image },
    queryString
) => {
    const imageNode = document.createElement('img');
    imageNode.classList.add('image-responsive');
    imageNode.src = image;
    const imageWrapperNode = document.createElement('div');
    imageWrapperNode.classList.add('image');
    imageWrapperNode.append(imageNode);
    const titleNode = document.createElement('span');
    titleNode.classList.add('title');
    titleNode.innerHTML = hightlightQueryMatching(name, queryString);
    const descriptionNode = document.createElement('span');
    descriptionNode.classList.add('description');
    descriptionNode.textContent = short_description;
    const infoNode = document.createElement('div');
    infoNode.classList.add('info');
    infoNode.append(titleNode, descriptionNode);
    const suggestionInnerNode = document.createElement('a');
    suggestionInnerNode.classList.add('input__suggestion-inner');
    suggestionInnerNode.href = link;
    suggestionInnerNode.append(imageWrapperNode, infoNode);
    const suggestionNode = document.createElement('li');
    suggestionNode.classList.add('input__suggestion');
    suggestionNode.append(suggestionInnerNode);
    return suggestionNode;
};

const removeSuggestionNodes = suggestionsNode => {
    const suggestionNodes = suggestionsNode.querySelectorAll(
        '.input__suggestion'
    );
    suggestionNodes.forEach(node => node.remove());
};

const getSuggestions = async (url, queryString) => {
    const { data } = await axios.get(url, { params: { search: queryString } });
    return data.data;
};

const autocomplete = _.debounce(async (inputWrapper, queryString) => {
    const apiUrl = inputWrapper.dataset.autocomplete_url;
    const suggestions = await getSuggestions(apiUrl, queryString);
    const suggestionsNode = inputWrapper.querySelector('.input__suggestions');
    removeSuggestionNodes(suggestionsNode);
    suggestions.forEach(suggestion => {
        const suggestionNode = createSuggestionNode(suggestion, queryString);
        suggestionsNode.append(suggestionNode);
    });
}, 500);

export const setAutocomplete = inputWrapper => {
    const input = inputWrapper.querySelector('input');
    input.addEventListener('input', () => {
        const queryString = input.value.toLowerCase();
        if (queryString.length < 3) {
            removeSuggestionNodes(inputWrapper);
        } else {
            autocomplete(inputWrapper, queryString);
        }
    });
};
