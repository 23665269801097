import { setAutocomplete } from '../autocomplete';

function setInputs() {
    setSearchInputs();
    setPasswordInputs();
    setTelInputs();
}

function setSearchInputs() {
    const searchInputWrappers = document.querySelectorAll('.input_search');
    searchInputWrappers.forEach(wrapper => {
        setAutocomplete(wrapper);
        const clearButton = wrapper.querySelector('.input__clear');
        const input = wrapper.querySelector('input');
        clearButton.addEventListener('click', () => {
            input.value = '';
            input.dispatchEvent(new Event('input'));
        });
    });
    const headerSearch = document.querySelector('.header__search');
    const catalogMenu = headerSearch.querySelector('.catalog-menu');
    const input = headerSearch.querySelector('input');
    input.addEventListener('focus', () => {
        headerSearch.classList.add('header__search_active');
        catalogMenu.classList.remove('catalog-menu_active');
    });
    input.addEventListener('blur', () => {
        headerSearch.classList.remove('header__search_active');
    });
}

function setPasswordInputs() {
    const passwordInputWrappers = document.querySelectorAll('.input_password');
    passwordInputWrappers.forEach(wrapper => {
        wrapper.addEventListener('password-toggle', () => {
            const input = wrapper.querySelector('input');
            const inputType = input.type === 'password' ? 'text' : 'password';
            input.setAttribute('type', inputType);
        });
        const togglePasswordButton = wrapper.querySelector(
            '.input__toggle-password'
        );
        if (togglePasswordButton) {
            togglePasswordButton.addEventListener('click', () => {
                togglePasswordButton.classList.toggle(
                    'input__toggle-password_active'
                );
                togglePasswordButton.dispatchEvent(
                    new CustomEvent('password-toggle', { bubbles: true })
                );
            });
        }
    });
}

function setTelInputs() {
    $('input[type=tel]').inputmask({
        mask: "+38 (999) 999-99-99",
        removeMaskOnSubmit:true,
        clearIncomplete: true
    });
    $(document).on("ajaxComplete", function(e){
        $('input[type=tel]').inputmask({
            mask: "+38 (999) 999-99-99",
            removeMaskOnSubmit:true,
            clearIncomplete: true
        });
    });
    // const telInputs = document.querySelectorAll('input[type=tel]');
    // telInputs.forEach(input => {
    //     input.addEventListener('input', () => {
    //         const x = input.value
    //             .replace(/\D/g, '')
    //             .match(/(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
    //         console.log(x);
    //         input.value = !x[2]
    //             ? x[1]
    //             : `(${x[1]}) ${x[2]}${x[3] ? ' ' + x[3] : ''}${
    //                   x[4] ? ' ' + x[4] : ''
    //               }`;
    //     });
    // });
}

export { setInputs };
