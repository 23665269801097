import { setSelects } from './components/select';
import { setInputs } from './components/input';

function setHeaderButtons() {
    const menuButton = document.querySelector('.header .menu-button');
    menuButton.addEventListener('click', () => {
        menuButton.classList.toggle('menu-button_active');
        const nav = document.querySelector('.header__nav');
        nav.classList.toggle('header__nav_visible');
    });
    const mobileMenuButton = document.querySelector(
        '.header_mobile .menu-button'
    );
    const mobileSearchButton = document.querySelector(
        '.header_mobile .search-button'
    );
    const mobileNav = document.querySelector('.nav-mobile');
    const mobileSearch = document.querySelector('.header_mobile .search');
    mobileMenuButton.addEventListener('click', () => {
        mobileSearchButton.classList.remove('search-open_active');
        mobileSearch.classList.remove('search_active');
        mobileMenuButton.classList.toggle('menu-button_active');
        mobileNav.classList.toggle('nav-mobile_visible');
        document.body.classList.toggle('non-scrollable');
    });
    mobileSearchButton.addEventListener('click', () => {
        mobileSearchButton.classList.toggle('search-open_active');
        mobileSearch.classList.toggle('search_active');
        mobileMenuButton.classList.remove('menu-button_active');
        mobileNav.classList.remove('nav-mobile_visible');
        document.body.classList.remove('non-scrollable');
    });
}

function setMenu() {
    const catalogMenus = document.querySelectorAll('.catalog-menu');
    catalogMenus.forEach(catalogMenu => {
        const catalogMenuButton = catalogMenu.querySelector(
            '.catalog-menu__button_mobile'
        );
        catalogMenuButton.addEventListener('click', () => {
            catalogMenu.classList.toggle('catalog-menu_active');
        });
        const backToMenuButton = catalogMenu.querySelector(
            '.catalog-menu__back_to-menu'
        );
        backToMenuButton.addEventListener('click', () => {
            catalogMenu.classList.remove('catalog-menu_active');
        });
        const catalogMenuItems = catalogMenu.querySelectorAll(
            '.catalog-menu__item'
        );
        catalogMenuItems.forEach(catalogMenuItem => {
            const catalogMenuItemInner = catalogMenuItem.querySelector(
                '.catalog-menu__item-inner_mobile'
            );
            catalogMenuItemInner.addEventListener('click', () => {
                catalogMenuItem.classList.add('catalog-menu__item_active');
            });
            const backToCatalogButton = catalogMenuItem.querySelector(
                '.catalog-menu__back_to-catalog'
            );
            backToCatalogButton.addEventListener('click', () => {
                catalogMenuItem.classList.remove('catalog-menu__item_active');
            });
        });
    });
}

function setExpansionPanels() {
    const expansionPanels = document.querySelectorAll('.expansion-panel');
    expansionPanels.forEach(panel => {
        const header = panel.querySelector('.expansion-panel__header');
        header.addEventListener('click', () => {
            panel.classList.toggle('expansion-panel_active');
        });
    });
}

function setModals() {
    const modalOpenButtons = document.querySelectorAll('.modal__open');
    const modalCloseButtons = document.querySelectorAll('.modal__close');
    modalOpenButtons.forEach(button => {
        button.addEventListener('click', () => {
            const { body } = document;
            body.style.paddingRight = 0;
            body.classList.remove('non-scrollable');
            const modals = [...document.querySelectorAll('.modal-backdrop')];
            modals.forEach(modal => (modal.hidden = true));
            const modalToShow = modals.find(
                modal => `#${modal.id}` === button.dataset.target
            );
            modalToShow.hidden = false;
            const bodyPadding = window.innerWidth - body.clientWidth;
            body.style.paddingRight = `${bodyPadding}px`;
            body.classList.add('non-scrollable');
        });
    });
    modalCloseButtons.forEach(button => {
        button.addEventListener('click', () => {
            const modal = document.querySelector(button.dataset.target);
            modal.hidden = true;
            const { body } = document;
            body.style.padding = 0;
            body.classList.remove('non-scrollable');
        });
    });
}

function setScrollToTopButton() {
    const scrollToTopButton = document.querySelector('.button-scroll-top');
    window.addEventListener('scroll', () => {
        if (window.scrollY > 0)
            scrollToTopButton.classList.add('button-scroll-top_visible');
        else scrollToTopButton.classList.remove('button-scroll-top_visible');
    });
    scrollToTopButton.addEventListener('click', () => {
        document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
    });
}

setSelects();
setInputs();
setHeaderButtons();
setMenu();
setExpansionPanels();
setModals();
setScrollToTopButton();

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$('body').on('submit', 'form.is-ajax', function(e) {
    e.preventDefault();
    let form = $(this);
    removeValidationError(form);
    $.ajax({
        url: form.attr('action'),
        type: 'post',
        data: new FormData($(this)[0]),
        processData: false,
        contentType: false,
        success: function(response) {
            if (response.success === true) {
                let modal = form.parents('.modal-backdrop').get(0);
                if (typeof modal !== 'undefined') {
                    modal.hidden = true;
                    document.body.classList.remove('non-scrollable');
                }
                toastr.success(response.data);
            } else {
                toastr.error(response.message);
            }
            if (response.reload === true) {
                location.reload();
            }
        },
        error: function(response) {
            validationError(form, response.responseJSON.data);
        }
    });
});

$('body').on('submit', '#login-modal form', function(e) {
    e.preventDefault();
    let form = $(this);
    removeValidationError(form);
    $.ajax({
        url: form.attr('action'),
        type: 'post',
        data: new FormData($(this)[0]),
        processData: false,
        contentType: false,
        success: function(response) {
            if (response.success === true) {
                toastr.success(response.data);
                location.reload();
            } else {
                toastr.error(response.message);
            }
        },
        error: function(response) {
            validationError(form, response.responseJSON.data);
        }
    });
});

$('body').on(
    'change',
    '#registration-modal [name="type"] + .select',
    function() {
        if ($('.select__option_selected').data('value') === 'company') {
            $('#registration-modal .company-only').show();
        } else {
            $('#registration-modal .company-only').hide();
        }
    }
);

function validationError(form, errors) {
    for (const [key, value] of Object.entries(errors)) {
        let block = form.find('.input-' + key);
        block.addClass('input_error');
        block
            .find('.input__details')
            .html('<div class="error-message">' + value[0] + '</div>');
    }
}

function removeValidationError(form) {
    form.find('.input').removeClass('input_error');
}

$('body').on('click', '.cart-btn', function(e) {
    e.preventDefault();
    let button = $(this);
    $.ajax({
        url: button.data('url'),
        type: 'post',
        data: {
            id: button.data('product_id'),
            quantity: 1
        },
        success: function(response) {
            if (response.success === true) {
                toastr.success(response.message);
                let total = $(response.data).find('.cart__list-item');
                $('#cart-modal .modal__body').html(response.data);
                $('.badge.badge_primary').html(
                    total.length > 0 ? total.length : ''
                );
            } else {
                toastr.error(response.message);
            }
        }
    });
});

$('body').on('click', '.trash-bin', function(e) {
    e.preventDefault();
    let button = $(this);
    $.ajax({
        url: button.data('url'),
        type: 'post',
        success: function(response) {
            if (response.success === true) {
                toastr.success(response.message);
                let total = $(response.data).find('.cart__list-item');
                $('#cart-modal .modal__body').html(response.data);
                $('.badge.badge_primary').html(
                    total.length > 0 ? total.length : ''
                );
            } else {
                toastr.error(response.message);
            }
        }
    });
});

$('body').on('click', '.item-minus, .item-plus', function(e) {
    e.preventDefault();
    let button = $(this);
    let input = button.parents('.counter').find('input');
    let change = input.val();
    let quantity = $(this).hasClass('item-minus') ? --change : ++change;
    $.ajax({
        url: input.data('url'),
        type: 'post',
        data: {
            id: input.data('item_id'),
            quantity: quantity
        },
        success: function(response) {
            if (response.success === true) {
                toastr.success(response.message);
                let total = $(response.data).find('.cart__list-item');
                $('#cart-modal .modal__body').html(response.data);
                $('.badge.badge_primary').html(
                    total.length > 0 ? total.length : ''
                );
            } else {
                toastr.error(response.message);
            }
        }
    });
});

$('body').on('change', '.cart__item input', function(e) {
    e.preventDefault();
    let input = $(this);
    $.ajax({
        url: input.data('url'),
        type: 'post',
        data: {
            id: input.data('item_id'),
            quantity: input.val()
        },
        success: function(response) {
            if (response.success === true) {
                toastr.success(response.message);
                let total = $(response.data).find('.cart__list-item');
                $('#cart-modal .modal__body').html(response.data);
                $('.badge.badge_primary').html(
                    total.length > 0 ? total.length : ''
                );
            } else {
                toastr.error(response.message);
            }
        }
    });
});
