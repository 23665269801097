function setSelects() {
    const selects = document.querySelectorAll('select');
    selects.forEach(createCustomSelect);
}

function createCustomSelect(select) {
    const customSelect = document.createElement('div');
    customSelect.classList.add('select');
    customSelect.tabIndex = 0;
    const options = [...select.querySelectorAll('option')];
    const customSelectLabel = createCustomSelectLabel(options);
    const customSelectOptions = createCustomSelectOptions(options);
    customSelect.append(customSelectLabel, customSelectOptions);
    customSelect.addEventListener('click', () => {
        customSelect.classList.toggle('select_active');
        const selectedOption = customSelectOptions.querySelector(
            '.select__option_selected'
        );
        selectedOption.scrollIntoView({ block: 'nearest' });
    });
    customSelect.addEventListener('change', e => {
        const oldSelectedOption = options.find(option => option.selected);
        oldSelectedOption.selected = false;
        const newSelectedOption = options.find(
            option => option.value === e.detail
        );
        newSelectedOption.selected = true;
        customSelectLabel.textContent = newSelectedOption.textContent;
        customSelect.classList.remove('select_active');
    });
    customSelect.addEventListener('blur', () => {
        customSelect.classList.remove('select_active');
    });
    select.after(customSelect);
    select.style.display = 'none';
}

function createCustomSelectLabel(options) {
    const customSelectLabel = document.createElement('span');
    customSelectLabel.classList.add('select__label');
    const selectedOption = options.find(option => option.selected);
    customSelectLabel.textContent = selectedOption.textContent;
    return customSelectLabel;
}

function createCustomSelectOptions(options) {
    const customSelectOptionsOuter = document.createElement('div');
    customSelectOptionsOuter.classList.add('select__options-outer');
    const customSelectOptionsInner = document.createElement('ul');
    customSelectOptionsInner.classList.add('select__options-inner');
    const customSelectOptions = options.map(createCustomSelectOption);
    customSelectOptionsInner.addEventListener('change', e => {
        customSelectOptions.forEach(option =>
            option.classList.remove('select__option_selected')
        );
        const newSelectedOption = customSelectOptions.find(
            option => option.dataset.value === e.detail
        );
        newSelectedOption.classList.add('select__option_selected');
    });
    customSelectOptionsInner.append(...customSelectOptions);
    customSelectOptionsOuter.append(customSelectOptionsInner);
    customSelectOptionsOuter.addEventListener('click', e => {
        e.stopPropagation();
    });
    return customSelectOptionsOuter;
}

function createCustomSelectOption(option) {
    const customSelectOption = document.createElement('li');
    customSelectOption.classList.add('select__option');
    customSelectOption.classList.toggle(
        'select__option_selected',
        option.selected
    );
    customSelectOption.dataset.value = option.value;
    customSelectOption.textContent = option.textContent;
    customSelectOption.addEventListener('click', () => {
        customSelectOption.classList.add('select__option_selected');
        customSelectOption.dispatchEvent(
            new CustomEvent('change', { detail: option.value, bubbles: true })
        );
    });
    return customSelectOption;
}

export { setSelects };
